export const salonsData = {
  salons: [
    {
      photo: "salons/interior00.jpg",
      title: "Salon 1 Title",
      location: "Warszawa, ul. Szczęśliwicka 42/3",
      openHours: [
        {
          days: "pn-pt",
          value: "09:00 - 18.00",
        },
        {
          days: "sb",
          value: "10.00 - 14.00",
        },
      ],
    },
    {
      photo: "salons/interior01.jpg",
      title: "Salon 2 Title",
      location: "Warszawa, ul. Janka Bytnara 16",
      openHours: [
        {
          days: "pn-pt",
          value: "09:00 - 18.00",
        },
        {
          days: "sb",
          value: "10.00 - 15.00",
        },
      ],
    },
  ],
}
export const serviceData = {
  sectionTitle: "Oferowane usługi",
  services: [
    {
      photo: "icons/cut-scissor.svg",
      title: "Service Description",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non ornare nisl. Aliquam dignissim vel elit sit.      ",
    },
    {
      photo: "icons/hair-comb.svg",
      title: "Service Description",
      description:
        "Lorem ipsum dolor sit amet. Praesent sit amet dolor iaculis erat porttitor cursus. Suspendisse.        ",
    },

    {
      photo: "icons/hairdryer.svg",
      title: "Service Description",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum interdum pharetra lorem ac. ",
    },

    {
      photo: "icons/beard.svg",
      title: "Service Description",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis placerat dui lorem ac.",
    },
  ],
}
