import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Collapse from "@kunukn/react-collapse"
import NavbarListItems from "./ListItemsComponent"
import companyLogo from "../../Images/barber-logo-white.png"
import "../../styles/mainNavbarCollapse.scss"

const NavbarWrapper = styled.div`
  background-color: #000;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 15px 10px;
  z-index: 1000;
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BrandName = styled.div`
  font-size: 18px;
  margin-left: 10px;
`

const UpperBelt = styled.div`
  display: flex;
  align-items: Center;
  &.desktop {
    .logo-wrapper {
      flex: 1;
    }
    .options-wrapper {
      flex: 3;
    }
  }

  &.mobile {
    .logo-wrapper {
      flex: 2;
      justify-content: flex-start;
    }
    .hamburger-wrapper {
      flex: 1;
      text-align: right;
      .hamburger-icon {
        cursor: pointer;
        font-size: 26px;
      }
    }
  }
`

const LogoImageWrapper = styled.img`
  height: 50px;
  margin: 0;
`

export default function MainNavbarComponent({ items }) {
  const [currentWidth, setCurrentWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  )
  const [mobileMenuBreakpoint] = useState(480)
  const [mobileMenuVisibleState, setMobileMenuVisibleState] = useState(false)

  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("resize", () => {
        setCurrentWidth(window.innerWidth)
      })
  }, [])

  function onHamburgerClick() {
    const mobileMenuPreviousState = mobileMenuVisibleState
    setMobileMenuVisibleState(!mobileMenuPreviousState)
  }
  return (
    <NavbarWrapper>
      <UpperBelt
        className={mobileMenuBreakpoint <= currentWidth ? "desktop" : "mobile"}
      >
        <LogoWrapper className="logo-wrapper">
          <LogoImageWrapper src={companyLogo}></LogoImageWrapper>
          <BrandName>Brand Name</BrandName>
        </LogoWrapper>
        {mobileMenuBreakpoint <= currentWidth && (
          <div className="options-wrapper">
            <NavbarListItems items={items} display="desktop"></NavbarListItems>
          </div>
        )}
        {mobileMenuBreakpoint > currentWidth && (
          <div className="hamburger-wrapper" onClick={onHamburgerClick}>
            <span className="hamburger-icon">&#9776;</span>
          </div>
        )}
      </UpperBelt>
      {mobileMenuBreakpoint > currentWidth && (
        <Collapse isOpen={mobileMenuVisibleState}>
          <NavbarListItems items={items} display="mobile"></NavbarListItems>
        </Collapse>
      )}
    </NavbarWrapper>
  )
}
