import React from "react"

import Header from "./mainPage/Header"
import Services from "./mainPage/Services"
import About from "./mainPage/About"
import MainPageSalons from "./mainPage/MainPageSalons"
import { navigate } from "gatsby"

export default function HomePageComponent() {
  const onSalonClick = salon => {
    navigate("/rezerwacja", {
      state: { test: "tests" },
    })
  }
  return (
    <>
      <Header />
      <About />
      <Services />
      <MainPageSalons
        onSalonClick={onSalonClick}
        sectionTitle={"Nasze salony"}
      />
    </>
  )
}
