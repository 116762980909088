import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import sampleImg from "../Images/gatsby-icon.png"
import HomePage from "../components/HomePageComponent"
import "../styles/main.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomePage></HomePage>
  </Layout>
)

export default IndexPage
