/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Navbar from "./mainNavbar/MainNavbarComponent"

import "./layout.css"

const ContentWrapper = styled.div`
  flex: 1 0 auto;
`

const FooterWrapper = styled.footer`
  background-color: #000;
  color: #fff;
  flex-shrink: 0;
  font-size: 22px;
  padding: 10px 0;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar
        items={[
          { link: "/", name: "Home" },
          { link: "/oferta", name: "Oferta" },
          { link: "/galeria", name: "Galeria" },
          { link: "/kontakt", name: "Kontakt" },
          { link: "/rezerwacja", name: "Rezerwacja" },
        ]}
      />
      <ContentWrapper>{children}</ContentWrapper>
      <FooterWrapper>Brand Name</FooterWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
