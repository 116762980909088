import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SingleSalonComponent from "./SingleObjectComponent"
import DefaultLoaderComponent from "./DefaultLoaderComponent"
import { getObjects } from "../../services/api"

const SalonsWrapper = styled.div`
  background-color: #fff;
  padding: 20px 0;
`
const SalonsHeader = styled.h3`
  font-weight: 600;
  text-align: center;
`
const SalonsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const SalonsFetchError = styled.div`
  text-align: center;
`

export default function Salons({
  onSalonClick,
  sectionTitle,
  buttonText,
  activeSalonId,
  availableSalons,
}) {
  const [availableObjects, setObjects] = useState(null)
  const [errorDuringDataDownload, setErrorDuringDataDownload] = useState(false)

  useEffect(() => {
    if (availableSalons) {
      setObjects(availableSalons)
      return
    }
    getObjects()
      .then(res => {
        setObjects(res.data)
      })
      .catch(() => {
        setErrorDuringDataDownload(true)
      })
  }, [])

  return (
    <SalonsWrapper>
      <SalonsHeader>{sectionTitle}</SalonsHeader>
      {availableObjects ? (
        <SalonsContent>
          {availableObjects.map((salon, salonId) => {
            return (
              <SingleSalonComponent
                imageHeight={120}
                imageWidth={200}
                key={salonId}
                salon={salon}
                buttonText={buttonText}
                clickCallback={() => onSalonClick(salon)}
                active={activeSalonId == salon.id}
              />
            )
          })}
        </SalonsContent>
      ) : setErrorDuringDataDownload ? (
        <SalonsFetchError>
          Podczas pobierania listy salonów wystąpił błąd.
        </SalonsFetchError>
      ) : (
        <DefaultLoaderComponent />
      )}
    </SalonsWrapper>
  )
}
