import React from "react"
import Salons from "../shared/Salons"

export default function MainPageSalons(props) {
  return (
    <div>
      <Salons {...props}></Salons>
    </div>
  )
}
